// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Link} from "react-router-dom";
import dpa from "../../Components/Technology/img/dpa.png";
import os_dpa_ch_principle from "../../Components/Technology/img/os-dpa-ch-principle.png";
import pdf_ico from "../../App/extra-icons/pdf_ico.png";
import i_ico from "../../App/extra-icons/i_ico.png";
import {Helmet} from "react-helmet";

/**
 * @class TechnologyDPAScene
 */
class TechnologyDPAScene extends React.Component {


    /**
     * Final Render
     * @returns {*}
     */
    render() {

        return (
            <div className={"page"}>
                <Helmet>
                    <title>DPA - IQRF</title>
                </Helmet>
                <Grid>
                    <Row>
                        <Col xs={12}>
                            <h1>DPA</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} xl={8}>
                            <h2>Three-layer IQRF architecture</h2>
                            <ul>
                                <li>Operating system <strong>IQRF OS</strong></li>
                                <li>Ready-to-use <strong>DPA</strong> (Direct Peripheral Access) – a higher
                                    communication
                                    layer above IQRF OS, with IQMESH networking
                                </li>
                                <li>Optional <strong>Custom DPA Handler</strong> extending DPA for specific
                                    functionality
                                    (either ready-to-use or programmed in C-language by an application engineer)
                                </li>
                            </ul>
                            <p>This three-layer architecture is applicable for <strong>network applications
                                only</strong>.
                                For non-network applications use the two-layer architecture directly under
                                <Link to={"/technology/os"}> OS </Link>
                                instead</p>
                        </Col>
                        <Col xs={12} xl={4}>
                            <img src={os_dpa_ch_principle} alt={"OS DPA Principle"}/>
                        </Col>
                    </Row>
                    <Row className="page__section--bottom-padding">
                        <Col xs={12}>
                            <p>DPA enables to apply wireless network in an extremely easy way. IQMESH network is
                                controlled by the <strong>DPA protocol</strong> from a <strong>control system </strong>
                                connected to the Coordinator via the <strong>SPI</strong> or <strong>UART </strong>
                                interface. Complete deployment is as simple as sending requests and receiving responses
                                and data.</p>
                            <div className="text--center">
                                <img src={dpa} alt={"DPA"}/>
                            </div>
                        </Col>
                    </Row>
                </Grid>
                <div className="page__section--with-theme">
                    <Grid>
                        <Row>
                            <Col xs={12}>
                                <h2 id="dpa-sw-and-documentation">DPA SW and documentation</h2>
                                <h3><em>DPA v4.31</em> for OS 4.06G - Current for TR-7xG</h3>
                                <div className="table--wrapper">
                                    <table className="table--with-links">
                                        <tbody>
                                        <tr>
                                            <td><Link to="/download/bacd7c72-2382-41c1-ac1d-e9377ce0ae31"
                                                   target="_self"><img alt="icon" src={pdf_ico}/> IQRF Quickstart guide
                                            </Link>
                                                – How to start
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Link to="/download/3c85028d-2f17-43a9-ae01-c607987f7504"
                                                   target="_self"><img alt="icon" src={pdf_ico}/> DPA Framework
                                                Technical guide</Link>
                                                – DPA protocol and related things – PDF version
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><a href="https://doc.iqrf.org/DpaTechGuide"
                                                   rel="noopener noreferrer"
                                                   target="_blank"><img alt="icon" src={i_ico}/> DPA Framework Technical
                                                guide </a>
                                                – DPA protocol and related things – On-line version
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Link to="/download/2b4e3da9-f9f5-4853-87b2-4733dcd5022f"
                                                   target="_self"><img alt="icon" src={pdf_ico}/> IQMESH Network
                                                Deployment Technical guide </Link>
                                                – How to implement IQRF network – PDF version
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><a href="https://doc.iqrf.org/IQMESH-Network-deployment/"
                                                   rel="noopener noreferrer"
                                                   target="_blank"><img alt="icon" src={i_ico}/> IQMESH Network
                                                Deployment Technical guide </a>
                                                - How to implement IQRF network – On-line version
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><a href="https://doc.iqrf.org/DpaTechGuide/examples/"
                                                   rel="noopener noreferrer"
                                                   target="_blank"><img alt="icon" src={i_ico}/>  Custom DPA Handler headers, examples and libraries </a>
                                                – Available also from IQRF Repository via IQRF IDE 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><a href="https://doc.iqrf.org/DpaTechGuide/index.html?page=dpa-release-notes.html"
                                                  rel="noopener noreferrer"
                                                  target="_blank"><img alt="icon" src={i_ico}/> Release notes </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h3><em>DPA v4.17</em> for OS 4.06D - Current for TR-7xD</h3>
                                <div className="table--wrapper">
                                    <table className="table--with-links">
                                        <tbody>
                                        <tr>
                                            <td><Link to="/download/782025c9-6b34-404d-a80a-184b21a35469"
                                                   target="_self"><img alt="icon" src={pdf_ico}/> DPA Framework
                                                Technical guide</Link>
                                                – DPA protocol and related things – PDF version
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><a href="https://doc.iqrf.org/DpaTechGuide/417"
                                                   rel="noopener noreferrer"
                                                   target="_blank"><img alt="icon" src={i_ico}/> DPA Framework Technical
                                                guide </a>
                                                – DPA protocol and related things – On-line version
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><a href="https://doc.iqrf.org/DpaTechGuide/417/examples/"
                                                   rel="noopener noreferrer"
                                                   target="_blank"><img alt="icon" src={i_ico}/>  Custom DPA Handler headers, examples and libraries </a>
                                                – Available also from IQRF Repository via IQRF IDE 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><a href="https://doc.iqrf.org/DpaTechGuide/417/index.html?page=dpa-release-notes.html"
                                                  rel="noopener noreferrer"
                                                  target="_blank"><img alt="icon" src={i_ico}/> Release notes </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <br/>
                                <Link to={"/technology/dpa/archive-dpa"}>» Archive </Link>
                            </Col>
                        </Row>
                    </Grid>
                </div>
                <Grid>
                    <Row className="page__section--with-margin">
                        <Col>
                            <h3>Compatibility of OS and DPA versions</h3>
                            <p><a href="/technology/dpa/OsDpaCompatibility">» Allowed combination</a> of OS and DPA versions in one network. </p>
                        </Col>
                    </Row>
                </Grid>
                <div className="page__section--with-theme">
                    <Grid>
                        <Row>
                            <Col xs={12}>
                                <h3>Calculators</h3>
                                <p><a href="https://doc.iqrf.org/DpaTechGuide/misc/IqMeshTiming.htm"
                                    rel="noopener noreferrer" target="_blank">» IQMESH timing calculator</a></p>
                                <p><Link to={"/technology/dpa/calculator"}>» DPA UART HDLC and CRC calculator</Link></p>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </div>
        );
    }

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyDPAScene);
