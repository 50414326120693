// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import pdf_ico from "../../App/extra-icons/pdf_ico.png";
import zip_ico from "../../App/extra-icons/zip_ico.png";
/**
 * @class TechnologyDPAArchiveScene
 */
class TechnologyDPAArchiveScene extends React.Component {


	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return (
			<Grid className={"page"}>
				<Helmet>
					<title>DPA - Archive - IQRF</title>
				</Helmet>
				<Row className="page__section--bottom-padding">
					<Col xs={12}>
						<h1>Archive DPA</h1>
					</Col>
					<Col xs={12}>
						<h3>DPA <em>v4.30</em> for OS 4.06G – <span className="text--smaller">Not for new designs.</span></h3>
						<hr/>
					</Col>
					<Col xs={12}>
						<h3>DPA <em>v4.18</em> for OS 4.06G – <span className="text--smaller">Not for new designs.</span></h3>
						<hr/>
					</Col>
					<Col xs={12}>
						<h3>DPA <em>v4.16</em> for OS 4.05D – <span className="text--smaller">Not for new designs.</span></h3>
						<hr/>
					</Col>
					<Col xs={12}>
						<h3>DPA <em>v4.15</em> for OS 4.04D – <span className="text--smaller">Not for new designs.</span></h3>
						<p><strong>Errata</strong><br/><strong>Known problem:</strong> An issue introduced in DPA V4.15 when internal reset of the _localFRC variable is missing in DPA function <a href="https://doc.iqrf.org/DpaTechGuide/415/index.html?page=dpaapilocalfrc.html" target="_blank" rel="noreferrer noopener">DpaApiLocalFrc</a>.
		<br/><strong>Workaround</strong> for DPA V4.15 under IQRF OS 4.04D : execute “_localFRC = FALSE;” immediately after calling DpaApiLocalFrc in the Custom DPA Handler.</p>
		<hr/>
					</Col>
					<Col xs={12}>
						<h3>DPA <em>v4.14</em> for OS 4.03D – <span className="text--smaller">Not for new designs.</span></h3>
						<hr/>
					</Col>
					<Col xs={12}>
						<h3>DPA <em>v4.13</em> for OS 4.03D - <span className="text--smaller">Obsolete. A minor bug present.</span></h3>
						<hr/>
					</Col>
					<Col xs={12}>
						<h3>DPA <em>v4.12</em> for OS 4.03D - <span className="text--smaller">Obsolete. A minor bug present.</span>
						</h3>
						<p><strong>Errata</strong><br/><strong>Known
							problem:</strong> An issue introduced in DPA V4.10 when "Routing off" is enabled at the
							Configuration
							and later disabled, then IQMESH routing is not working
							anyway.<br/><strong>Workaround</strong> for DPA V4.10: call setRoutingOn() at the Init event
							in the Custom DPA Handler.</p>
						<hr/>
					</Col>
					<Col xs={12}>
						<h3>DPA <em>v4.11</em> for OS 4.03D - <span className="text--smaller">Obsolete. A minor bug present.</span></h3>
						<hr/>
					</Col>
					<Col xs={12}>
						<h3><em>DPA v4.10</em> for OS 4.03D - Current for TR-7xD</h3>
						<p>Obsolete. Two bugs present.</p>
						<p><strong>Errata</strong></p>
						<p><strong>Known problem:</strong> An issue when "Routing off" is enabled at the Configuration and later disabled, then IQMESH routing is not working anyway.<br></br>
							Introduced in: DPA v4.10.<br></br>
							<strong>Workaround:</strong> Use <a href=" https://repository.iqrfalliance.org/download/dpa/4.11/" target="_blank">DPA v4.11</a>. For DPA V4.10: call setRoutingOn() at the Init event in the Custom DPA Handler.
						</p>
					</Col>
					<Col xs={12}>
						<h3><em>DPA v4.03</em> for OS 4.03D - <span className="text--smaller">Not recommended for new designs</span></h3>
					</Col>
					<Col xs={12}>
						<h3>DPA <em>v4.02</em> for OS 4.03D - <span className="text--smaller">Obsolete. A bug present.</span></h3>
						<p><strong>Errata:</strong> Any of MemoryRead+1 FRC commands also executed a DPA Request stored
							internally at bufferAUX (e.g. from the previous FRC AcknowledgeBroadcast). This may cause a
							problem expecially in Autonetwork.<br/><strong>Workaround:</strong> Use DPA v4.03.</p>
						<hr/>
						<h3>DPA <em>v4.01</em> for OS 4.03D - <span className="text--smaller">Not recommended for new designs</span></h3>
						<hr/>
						<h3>DPA <em>v4.00</em> for OS 4.03D - <span className="text--smaller">Not recommended for new designs</span></h3>
						<hr/>
						<h3>DPA <em>v3.03</em> for OS 4.03D - <span className="text--smaller">Not recommended for new designs</span></h3>
						<p><strong>Errata</strong>: <a href="https://doc.iqrf.org/DpaTechGuide/pages/SendFrc.html"
													   rel="noopener noreferrer" target="_blank">Send </a>
							and <a href="https://doc.iqrf.org/DpaTechGuide/pages/send-selective.html"
								   rel="noopener noreferrer" target="_blank">Send Selective </a>
							commands of the
							<a href="https://doc.iqrf.org/DpaTechGuide/pages/frc.html"
							   rel="noopener noreferrer" target="_blank"> FRC </a>
							peripheral pass only 20 bytes of the UserData parameter. This <strong>bug</strong> was
							introduced into DPA v3.03. If it is bugging you, contact IQRF
							<Link to={"/support/contact-support"}> support</Link> for the workaround, please.
							We apologize for your inconvenience.</p>
						<hr/>
						<h3>DPA <em>v3.02</em> for OS 4.02D - <span className="text--smaller">Not recommended for new designs</span></h3>
						<p><strong>Known issues:</strong></p>
						<p>
							<ul>
								<li><strong>SPI Peripheral</strong>: The DPA for Node without DPA interface support at
									standard RF mode (HWP-Node-STD-7xD-V302-171116.iqrf or HWP-Node-STD-7xD-V301-170814.iqrf)
									does not initialize enabled SPI Peripheral.<br/>
									<strong>Workaround</strong>: Call enableSPI() at DpaEvent_Init event.
								</li>
								<li><strong>UART interface shutdown</strong>: The DPA for Coordinator with a UART interface
									(HWP-Coordinator-STD-UART-7xD-V302-171116.iqrf or HWP-Coordinator-LP-UART-7xD-V302-171116.iqrf)
									does not shutdown the UART interface before Discovery, Reset, Restart, Run RFPGM and LoadCode
									commands are executed. This might cause a malfunctioning in case of Discovery or missing
									DPA Response in other cases.<br/>
									<strong>Workaround</strong>: Enable Node interface at the HWP Configuration although
									the device is the Coordinator.
								</li>
							</ul>
						</p>
						<p>These issues are fixed in DPA v3.03. Thus, the best workaround is to <strong>migrate to OS
							v4.03D and DPA v3.03</strong>.</p>
						<hr/>
						<h3>DPA <em>v3.01</em> for OS 4.02D - <span className="text--smaller">Not recommended for new designs</span></h3>
						<hr/>
						<h3>DPA <em>v3.00</em> for OS 4.00D - <span className="text--smaller">Not recommended for new designs</span></h3>
						<hr/>
						<h3>DPA <em>v2.28</em> for OS 3.08D - <span className="text--smaller">Not recommended for new designs</span></h3>
						<div className="table--wrapper">
							<table className="table--with-links">
								<tbody>
								<tr>
									<td><Link to="/download/18ac722f-64f2-4601-bd32-c12817053abd"
										   target="_self"><img alt={"icon"} src={pdf_ico}/> DPA
										Framework Technical guide</Link></td>
								</tr>
								<tr>
									<td><Link to="/download/e3188b7b-9c6e-4ec4-b8ff-d7336b6f83f9"
										   target="_self"><img src={zip_ico} alt={"icon"}/> HWP
									</Link> - <span className="text--success"><strong>Final</strong> release for <strong>DCTR-5xD</strong></span></td>
								</tr>
								</tbody>
							</table>
						</div>
						<h3>DPA <em>v2.27</em> for OS 3.08D - <span className="text--smaller">Not recommended for new designs</span></h3>
						<hr/>
						<h3>DPA <em>v2.26</em> for OS 3.08D - <span className="text--smaller">Not recommended for new designs</span></h3>
						<hr/>
						<h3>DPA <em>v2.24</em> for OS 3.07D - <span className="text--smaller">Not recommended for new designs</span></h3>
						<hr/>
						<h3>DPA <em>v2.23</em> for OS 3.07D - <span className="text--smaller">Obsolete. Serious bug present.</span></h3>
						<hr/>
						<h3>DPA <em>v2.22</em> for OS 3.07D - <span className="text--smaller">Obsolete. Serious bug present.</span></h3>
						<hr/>
						<h3>DPA <em>v2.21</em> for OS 3.07D - <span className="text--smaller">Obsolete. Serious bug present.</span></h3>
						<hr/>
						<h3>DPA <em>v2.20</em> for OS 3.07D - <span className="text--smaller">Obsolete. Serious bug present.</span></h3>
						<hr/>
						<h3>DPA <em>v2.13</em> for OS 3.06D - <span className="text--smaller">Not recommended for new designs</span></h3>
						<div className="table--wrapper">
							<table className="table--with-links">
								<tbody>
								<tr>
									<td><Link to="/download/a1f5ff67-2e12-4ecb-9a52-3f51a43e2171"
										    target="_self"><img alt={"icon"} src={pdf_ico}/> DPA
										Quickstart guide</Link></td>
								</tr>
								<tr>
									<td><Link to="/download/914ae1e3-68c1-4aa7-a9bb-aa59e5e8a35a"
										   target="_self"><img alt={"icon"} src={pdf_ico}/> DPA
										Framework Technical guide</Link></td>
								</tr>
								<tr>
									<td><Link to="/download/7e730d2a-58c7-4f27-9e47-cb72c8f8e337"
										   target="_self"><img src={zip_ico} alt={"icon"}/> Demo
										HWP</Link></td>
								</tr>
								<tr>
									<td><Link to="/download/706789dd-6f50-4f1d-ada8-4a44053a3e4d"
										   target="_self"><img src={zip_ico} alt={"icon"}/> General
										HWP</Link></td>
								</tr>
								<tr>
									<td><Link to="/download/4ea8527b-d1d7-4b45-9203-03e3125166c4"
										   target="_self"><img src={zip_ico} alt={"icon"}/> Custom
										handler examples</Link></td>
								</tr>
								</tbody>
							</table>
						</div>
						<h3>DPA <em>v2.12</em> for OS 3.06D - <span className="text--smaller">Obsolete</span></h3>
						<hr/>
						<h3>DPA <em>v2.11</em> for OS 3.06D - <span className="text--smaller">Obsolete</span></h3>
						<hr/>
						<h3>DPA <em>v2.10</em> for OS 3.06D - <span className="text--smaller">Obsolete</span></h3>
						<hr/>
						<h3>DPA <em>v2.01</em> for OS 3.05D - <span className="text--smaller">Not recommended for new designs</span></h3>
						<div className="table--wrapper">
							<table className="table--with-links">
								<tbody>
								<tr>
									<td><Link to="/download/9888ba7a-cbc8-4924-98b6-698fe63e11cb"
										   target="_self"><img alt={"icon"} src={pdf_ico}/> DPA
										Quickstart guide</Link></td>
								</tr>
								<tr>
									<td><Link to="/download/7a6cce32-d0f2-486e-bf7b-828dfa26a53e"
										   target="_self"><img alt={"icon"} src={pdf_ico}/> DPA
										Framework Technical guide</Link></td>
								</tr>
								<tr>
									<td><Link to="/download/3f63998f-ed0c-4986-9391-38dd6b9e88df"
										   target="_self"><img alt={"icon"} src={pdf_ico}/> Demo
										HWP</Link></td>
								</tr>
								<tr>
									<td><Link to="/download/f8194a15-b61a-4c67-9dc7-e92e0a4c7d57"
										   target="_self"><img src={zip_ico} alt={"icon"}/> General
										HWP</Link></td>
								</tr>
								<tr>
									<td><Link to="/download/f02345b6-a881-464f-acb4-1a0041b62de7"
										   target="_self"><img src={zip_ico} alt={"icon"}/> Custom
										handler examples</Link></td>
								</tr>
								</tbody>
							</table>
						</div>
						<h3>DPA <em>v2.00</em> for OS 3.05D - <span className="text--smaller">Obsolete</span></h3>
						<hr/>
						<h3>DPA <em>v1.00</em> for OS 3.04D - <span className="text--smaller">Not recommended for new designs</span></h3>
						First release
						<div className="table--wrapper">
							<table className="table--with-links">
								<tbody>
								<tr>
									<td><Link to="/download/9888ba7a-cbc8-4924-98b6-698fe63e11cb"
										   target="_self"><img alt={"icon"} src={pdf_ico}/> DPA
										Quickstart guide</Link></td>
								</tr>
								<tr>
									<td><Link to="/download/0ad400f2-1c9e-496b-b9a4-a593e0aa1c51"
										   target="_self"><img alt={"icon"} src={pdf_ico}/> DPA
										Framework Technical guide</Link></td>
								</tr>
								<tr>
									<td><Link to="/download/b0970470-4312-49aa-bf70-d99d8e14a084"
										   target="_self"><img src={zip_ico} alt={"icon"}/> Demo
										HWP</Link></td>
								</tr>
								<tr>
									<td><Link to="/download/15d19403-1493-4c77-8018-55136127dfdc"
										   target="_self"><img src={zip_ico} alt={"icon"}/> General HWP</Link></td>
								</tr>
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
			</Grid>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyDPAArchiveScene);
